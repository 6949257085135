import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress, { set } from "nprogress";
import { useLang } from "../../helpers/language";
import moment from "moment";
import {
	Avatar,
	Badge,
	Box,
	Card,
	Flex,
	Table,
	Text,
	Title,
	useMantineColorScheme,
} from "@mantine/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function OrderList() {
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [clients, setClients] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);
	const [total, setTotal] = useState({
		price: 0,
		paid: 0,
		debt: 0,
		difference: 0,
	});

	let renderDetailPanel = ({ row: { original } }) => {
		return (
			<Card shadow="xs" style={{ width: "100%" }}>
				<Box component="table" style={{ width: "100%", borderCollapse: "collapse" }}>
					<Table striped highlightOnHover>
						<Table.Thead>
							<Table.Tr>
								<Table.Th>{config.translate.product[lang]}</Table.Th>
								<Table.Th>{config.translate.price[lang]}</Table.Th>
								<Table.Th>{config.translate.quantity[lang]}</Table.Th>
								<Table.Th>{config.translate.totalPrice[lang]}</Table.Th>
							</Table.Tr>
						</Table.Thead>
						<Table.Tbody>
							{original?.cart?.map((item, index) => (
								<Table.Tr key={index}>
									<Table.Td>
										<Text>{item.product?.ID}</Text>
									</Table.Td>
									<Table.Td>
										<Text c="cyan">{item.price}</Text>
									</Table.Td>
									<Table.Td>
										<Text c="grey">{item.quantity}</Text>
									</Table.Td>
									<Table.Td>
										<Text c="teal">{item.totalPrice}</Text>
									</Table.Td>
								</Table.Tr>
							))}
						</Table.Tbody>
					</Table>
				</Box>
			</Card>
		);
	};

	function setRefreshColumns() {
		return [
			{
				accessorKey: "ID",
				permissionModel: "ID",
				header: "ID",
				size: 50,
				enableClickToCopy: true,
			},
			{
				accessorFn: (row) => row.client?.name,
				permissionModel: "client",
				header: config.translate.client[lang],
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: clients.map((client) => ({
						value: client._id,
						label: client.name,
					})),
				},
				size: 300,
				columnSizing: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.client?.name;
				},
			},
			{
				accessorKey: "price",
				permissionModel: "price",
				header: config.translate.price[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.price;
				},
			},
			{
				accessorKey: "paid",
				permissionModel: "paid",
				header: config.translate.paid[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.paid;
				},
			},
			{
				accessorKey: "debt",
				permissionModel: "debt",
				header: config.translate.debt[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.debt;
				},
			},
			{
				accessorKey: "difference",
				permissionModel: "debt",
				header: config.translate.difference[lang],
				enableColumnFilter: false,
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = row.cart.filter((item) => item.difference);
					return (
						<Flex wrap="wrap">
							{result.map((item, index) => (
								<Badge
									key={index}
									color={item.difference < 0 ? "red.6" : "green.6"}
									size="lg"
									m={2}
								>
									{item.difference}
								</Badge>
							))}
						</Flex>
					);
				},
			},
			{
				accessorKey: "date",
				permissionModel: "date",
				header: config.translate.date[lang],
				enableClickToCopy: true,
				filterVariant: "date-range",
				size: 300,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = moment(row.date).format("DD-MM-YYYY");
					return result;
				},
			},
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			// Fetch clients
			let rawClients = await API.get(config.api.client);
			if (rawClients) setClients(rawClients);

			// Fetch orders and calculate total
			let rawOrders = await API.get(config.api.order);
			if (rawOrders) {
				let calculatedTotal = rawOrders.reduce(
					(acc, item) => {
						acc.price += item.price || 0;
						acc.paid += item.paid || 0;
						acc.debt += item.debt || 0;
						acc.difference += (item.cart || []).reduce(
							(innerAcc, cartItem) => innerAcc + (cartItem.difference || 0),
							0
						);
						return acc;
					},
					{ price: 0, paid: 0, debt: 0, difference: 0 }
				);
				setTotal(calculatedTotal);
			}

			// Set table columns and finalize loading
			setColumns(setRefreshColumns());
			NProgress.done();
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.order}
				query={{}}
				sorting={{ ID: -1 }}
				permissionModel={"order"}
				uploadType={null}
				limit={null}
				customFiltersArrays={{
					clients: clients,
				}}
				populate={["client", "cart.product"]}
				columns={columns}
				perPageHeader={true}
				pageSize={25}
				header={[
					{
						title: config.translate.price[lang],
						value: total.price,
						color: "blue.6",
					},
					{
						title: config.translate.paid[lang],
						value: total.paid,
						color: "cyan.6",
					},
					{
						title: config.translate.debt[lang],
						value: total.debt,
						color: "red.6",
					},
					{
						title: config.translate.difference[lang],
						value: total.difference,
						color: total.difference < 0 ? "red.9" : "green.6",
					},
				]}
				renderDetailPanel={renderDetailPanel}
			/>
		);
}

import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../helpers/language";
import moment from "moment";
import { useMantineColorScheme } from "@mantine/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Text } from "@mantine/core";
import parse from "html-react-parser";

let formattedNumber = (number) =>
	new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(number);

export default function ProductList() {
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [clients, setClients] = useState([]);
	const [orders, setOrders] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);
	const [totalProductsPrice, setTotalProductsPrice] = useState(0);

	function setRefreshColumns() {
		return [
			{
				accessorKey: "ID",
				permissionModel: "ID",
				header: "ID",
				size: 100,
				enableClickToCopy: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = row.ID ? row.ID : 0;
					return String(result);
				},
			},
			{
				accessorKey: "price",
				permissionModel: "price",
				header: config.translate.price2[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return formattedNumber(row.price);
				},
			},
			{
				accessorKey: "totalPrice",
				permissionModel: "price",
				header: config.translate.price[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					if (row.price && row.weight) {
						let result = row.price * row.weight;
						return <Text fw="bold">{formattedNumber(result)}</Text>;
					} else {
						return 0;
					}
				},
			},
			{
				accessorKey: "weight",
				permissionModel: "weight",
				header: config.translate.weight[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return parseFloat(row.weight).toFixed(3);
				},
			},
			{
				accessorKey: "desc",
				permissionModel: "desc",
				header: config.translate.desc[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = row.desc ? row.desc : "...";
					return parse(result.length > 20 ? result.slice(0, 20) + "..." : result);
				},
			},
			{
				accessorKey: "quantity",
				permissionModel: "quantity",
				header: config.translate.quantity[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.quantity;
				},
			},
			{
				accessorKey: "diameter",
				permissionModel: "diameter",
				header: config.translate.diameter[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.diameter;
				},
			},
			{
				accessorKey: "taxCode",
				permissionModel: "taxCode",
				header: config.translate.taxCode[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.taxCode;
				},
			},
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawProducts = await API.get(config.api.product);
			if (rawProducts) setClients(rawProducts);

			setTotalProductsPrice(
				rawProducts.reduce((acc, product) => {
					return (
						acc +
						(product.price ? product.price : 1) * (product.weight ? product.weight : 1)
					);
				}, 0)
			);

			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	return (
		<ListPage
			route={config.api.product}
			sorting={{ ID: "desc" }}
			permissionModel={"product"}
			uploadType={null}
			limit={null}
			columns={columns}
			pageSize={25}
			header={[
				{
					title: config.translate.totalPrice[lang],
					value: totalProductsPrice,
					color: "green.6",
				},
			]}
		/>
	);
}
